import React, { useState } from 'react';
import styled from "styled-components";
// import LineChart from "./LineChart";
import Modal from './ControlUnitModal';
import { Link } from "react-router-dom";

const Node = ({props, isMobile, edit, ru13, ru40, area}) => {
    const [showmodal, setShowmodal] = useState(false);

    const closeModal = () => {
        setShowmodal(false);
        window.location.reload();
    }

    return (
        <Items>
            {props.map((node) => (
                <Item key={node.id}>
                    {node.model === "RUC3-LTE-EU" &&
                        <>
                            <Wrap onClick={() => setShowmodal(true)}>
                                <Sensor><Orange /> {node.name}</Sensor>
                                {node.description && <p>{node.description}</p>}
                                <br/>
                                <Icon><Image src={'/level.svg'} alt="level icon" /> {node.rcu.target}mm <SmallItalic>(target level)</SmallItalic></Icon><br/>
                                {/* <StyledButton type="button" onClick={() => setShowmodal(true)}>Aanpassen</StyledButton> */}
                                {showmodal && <Modal props={node.rcu} onClose={closeModal}/>}
                            </Wrap>
                        </>
                    }
                    {node.model !== "RUC3-LTE-EU" &&
                        <Link to={`/node-details/${node.id}`}  state={{ node }} >
                            <Wrap>
                            {node.model === "RU40-NBIOT-EU" &&
                                <>
                                    <Sensor><Blue /> {node.name} <Jump>&rarr;</Jump></Sensor>
                                    {node.description && <p>{node.description}</p>}
                                    {!node.actuals.status ? (
                                        <SmallRed>Laatste meting ({node.actuals.last_date_time ? (node.actuals.last_date_time):(<>nb</>)})</SmallRed>
                                    ) : (
                                        <Small>Laatste meting ({node.actuals.last_date_time ? (node.actuals.last_date_time):(<>nb</>)})</Small>
                                    )}<br/>
                                        <Icons>
                                        <Icon><Image src={'/level.svg'} alt="level icon" /> {node.actuals.level}mm</Icon>
                                    </Icons>
                                    {/* <StyledButton2 type="button" >Aanpassen &rarr;</StyledButton2> */}
                                </>
                            }
                            {node.model === "RU13-NBIOT-EU" &&
                                <>
                                    <Sensor><Green /> {node.name} <Jump>&rarr;</Jump></Sensor>
                                    {node.description && <p>{node.description}</p>}
                                    {!node.actuals.status ? (
                                        <SmallRed>Laatste meting ({node.actuals.last_date_time ? (node.actuals.last_date_time):(<>nb</>)})</SmallRed>
                                    ) : (
                                        <Small>Laatste meting ({node.actuals.last_date_time ? (node.actuals.last_date_time):(<>nb</>)})</Small>
                                    )}<br/>
                                    <Icons>
                                        <Icon><Image src={'/drop.svg'} alt="drop icon" /> {node.actuals.vwc}%</Icon>
                                        <Icon><Image src={'/temperature.svg'} alt="temperature icon" /> {node.actuals.temp}&#8451;</Icon>
                                    </Icons>
                                    {/* {!isMobile ? (
                                        <div style={{width:400}}>
                                            <LineChart chartData={node.linechart} />
                                        </div>
                                    ) : (
                                        <div style={{width:250}}>
                                            <LineChart chartData={node.linechart} />
                                        </div>    
                                    )} */}
                                </>
                            }
                            {/* {node.model === "RUC3-LTE-EU" &&
                                <>
                                    <Sensor><Orange /> {node.name}</Sensor>
                                    {node.description && <p>{node.description}</p>}
                                    <br/>
                                    <Icon><Image src={'/level.svg'} alt="level icon" /> {node.rcu.target}mm <SmallItalic>(target level)</SmallItalic></Icon><br/>
                                </>
                            } */}
                            </Wrap>
                        </Link>
                    }
                </Item>
            ))}
        </Items>
    )
};

export const StyledForm = styled.form`
  border: 1px solid #212121;
  padding: 20px;
  border-radius: 5px;
`
export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const StyledInput = styled.input`
  width:20%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`
export const StyledButton = styled.button`
    min-width: 50px;
    border-radius: 4px;
    padding: 6px 12px;
    border: none;
    background: #EB5A37;
    color: #fff;
`;

export const StyledButton2 = styled.button`
    min-width: 50px;
    // border-radius: 4px;
    margin-top:4px;
    // padding: 6px 12px;
    border: none;
    // background: #fff;
    color: #000;
    background: #f9f9f9;
    text-decoration: underline;
    // border: 1px solid #000;
`;

const Green = styled.p`
    box-sizing: border-box;
    width: 8px;
    height: 7px;
    border-radius: 10px;
    background-color: #74C733;
    margin: 7px 7px 7px 0px;
`;

const Jump = styled.span`
    margin-left: 8px;
    font-weight: bold;
`;

const Blue = styled.p`
    box-sizing: border-box;
    width: 8px;
    height: 7px;
    border-radius: 10px;
    background-color: #82B8D1;
    margin: 7px 7px 7px 0px;
`;

const Orange = styled.p`
    box-sizing: border-box;
    width: 8px;
    height: 7px;
    border-radius: 10px;
    background-color: #EB5A37;
    margin: 7px 7px 7px 0px;
`;

const Sensor = styled.div`
    display:flex; 
    flex-direction: row; 
    // justify-content: center; 
    // align-items: center"
`;

const Title = styled.div`
font-size:16px;
font-weight:bold;
`;

const Small = styled.p`
    font-size: 12px;
    color: grey;
`;

const SmallItalic = styled.span`
    font-size: 12px;
    font-style: italic;
    color: grey;
`;

const SmallNormal = styled.p`
    font-weight: normal;
    font-size: 12px;
    color: grey;
`;

const SmallRed = styled.p`
    font-size: 12px;
    color: #F44336;
`;

const Wrap = styled.div`
    // width: 100%; /* this should be dynamic! */
    border: 1px solid #E0E4E5;
    border-radius: 10px;
    padding: 12px;
    background: #f9f9f9;
    // background: #fff;
    // margin-bottom: 8px;

    @media screen and (max-device-width: 400px){
        min-height: 50px;
    }
`;

const HorLine = styled.div`
    width: 100%;
    border-bottom:1px solid #eeeeee;
    margin-bottom: 12px;
`;

const Items = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
 `;

 const Item = styled.div`
    background:#fff;
    // border: 1px solid #eeeeee;
    border-radius: 8px;
    box-sizing: border-box;
    // margin: 1rem .25em;
    margin-top: 1rem;
    margin-bottom: 1rem;
    // padding: 20px;
    flex-grow: 1;
 `;

 const SubHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    justify-content: space-between;
`;

const Open = styled.span`
    margin-left:14px;
    background: #F44336;
    font-weight: bold;
    color: #fff;
    display: inline-block;
    padding: 8px;
    border-radius: 8px;
`;

const Closed = styled.span`
    margin-left:14px;
    background: #4CAF50;
    font-weight: bold;
    color: #fff;
    display: inline-block;
    padding: 8px;
    border-radius: 8px;
`;

const Alert = styled.div`
    background: #F8D7DA;
    padding: 24px;
    color: #77232B;
`;

const Icons = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
`;

const Icon = styled.div`
background:#f9f9f9;
box-sizing: border-box;
padding-right: 22px;
`;

const Image = styled.img`
    padding-top: 0.5rem;
    height: 16px;
    margin-right: 6px;
`;

export default Node;