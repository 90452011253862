import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";
import Verified from "./Verified";
import styled from "styled-components";
import Signup from './Signup';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { authenticate } = useContext(AccountContext);
    const [login, setLogin] = useState(false);
    const [signup, setSignup] = useState(false);
    const { getSession } = useContext(AccountContext);

    useEffect(() => {
      getSession()
        .then(session => {
          console.log("Session: ", session);
          setLogin(true);
        })
        .catch(err => {
          console.error(err);
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (event) => {
        event.preventDefault();
      if(email && password){
        authenticate(email, password)
            .then(data => {
                console.log("logged in!", data);
                setLogin(true);
            })
            .catch(err => {
                console.error("Failed to login", err);
            })
      }else{
        console.log("email or password is empty");
      }
    };

    const handleChange = () => {
      setSignup(true);
    };

    return (
      <>
        {!login && !signup &&
            <Navbar.Wrapper>
              <Navbar.Logo><Image src={'/logo-rainup-pro_v2.svg'} alt="rainup pro logo" /></Navbar.Logo>
              <Title>Jouw online<br/>waterbeheer omgeving</Title>
              <form onSubmit={onSubmit}>
                  <label htmlFor="email">Email</label>
                  <Input
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                  ></Input>
                  <label htmlFor="password">Password</label>
                  <Input
                      value={password}
                      type="password"
                      onChange={(event) => setPassword(event.target.value)}
                  ></Input>

                  <ButtonSubmit type="submit">inloggen &#8594;</ButtonSubmit><Button type="submit" onClick={handleChange}>Maak een account</Button>
              </form>
            </Navbar.Wrapper>
          }
          {signup &&
            <>
              <Signup />
            </>
          }
          {login && !signup && 
            <Verified />
          }
      </>
    )
};

export default Login;

const Input = styled.input`
  background-image: linear-gradient(#20aee3, #20aee3), linear-gradient(#bfbfbf, #bfbfbf);
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  float: none;
  background-color: transparent;
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: background 0s ease-out 0s;
  color: #bfbfbf;
  min-height: 35px;
  display: initial;
  width: 100%;
  outline: none;
  font-size: 15px;
  &:focus {
      background-size: 100% 2px, 100% 1px;
      outline: 0 none;
      transition-duration: 0.3s;
      color: #525252;
    }
`;

const Title = styled.div`
    font-size: 42px;
    margin-right: 50px;
    margin-bottom: 40px;
    font-family: 'SuisseIntl';
`;

const ButtonSubmit = styled.button`
    min-width: 50px;
    padding: 14px;
    background: #EB5A37;
    border-radius: 3px;
    border: none;
    margin-top: 20px;
    color: #fff;
    font-family: 'SuisseIntl';
`;

const Button = styled.button`
    min-width: 50px;
    background: none;
    border: none;
    float: right;
    margin-top: 35px;
    text-decoration: underline;
    font-family: 'SuisseIntl';
    font-size: 14px;
    color: #BDBDBD;
`;

const Image = styled.img`
    padding-top: 0.5rem;
    height: 35px;
`;

const Navbar = {
  Wrapper: styled.nav`
    flex: 1;

    align-self: flex-start;

    padding: 1rem 3rem;

    display: flex;
    flex-direction: column;

    background-color: #E1E4E5;
  `,
  Logo: styled.h1`
  //   border: 1px solid gray;
  //   padding: 0.5rem 1rem;
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
  `,
  Itemx: styled.li`
    cursor: pointer;
    font-weight: bold;
    padding: 14px;
    background: #EB5A37;
    margin-right: 5px;
    border-radius: 4px;
  `,
  Item: styled.li`
    cursor: pointer;
    font-weight: bold;
    padding: 14px;
    background: #fff;
    margin-right: 5px;
    border-radius: 4px;
  `,
};
