import styled from "styled-components";

const Contact = () => {
    return (
        <Wrapper>
            <h1>Contact Articles</h1>
        </Wrapper>
    )
};

const Wrapper = styled.main`
  padding: 2em;
`;

export default Contact;