import React from "react";
import styled from "styled-components";

const ProgressBar = (props) => {
const { capacity, volume, title, bgcolor, completed } = props;

const containerStyles = {
    height: 20,
    // width: '100%',
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: 20,
}

const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right'
}

const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
}

    return (
        <>
            <Center>{title}</Center>
            <Center2>volume ({volume} L) / capaciteit ({capacity} L)</Center2>
            <div style={containerStyles}>
                <div style={fillerStyles}>
                <SpanStyle style={labelStyles}>{`${completed}%`}</SpanStyle>
                </div>
            </div>
        </>
    );
};

const Center = styled.h1`
text-align: center;
`;

const SpanStyle = styled.span`
    color: white !important;
`;

const Center2 = styled.div`
font-size: 12px;
color: grey;
text-align: center;
`;

export default ProgressBar;