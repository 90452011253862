import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import NodesService from '../services/nodes';

import { withAuthenticator } from '@aws-amplify/ui-react';

const Nodes = ({ user, area }) => {
    const [nodes, setNodes] = useState([]);
    const dataFetchedRef = useRef(false);
    const params = useParams();
    const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

    async function getNodesForArea() {
        NodesService.getNodes(user, params.id).then(result => {
            if(result.status === "200 OK"){
                console.log("ok", result);
                setNodes(result.data);
            } else {
                setNodes();
                console.log('Fetch error: ', result.status);
            }
        });
    }

    useEffect(() => {
        //we use useRef to keep track of a boolean. 
        //If it’s true, we’ve already been here, and we can return. 
        //If it is false, we can perform our fetch and then update the ref.
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getNodesForArea();
        console.log(area);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <Header>
                {/* <Title><Link onClick={goBack}>&#8592;</Link> terug naar project</Title> */}
                <Link onClick={goBack}><Image src={'/arrow_left.svg'} alt="arrow left" /> terug naar project</Link>
            </Header>
            {!nodes ? (
                <>
                <Alert>Er zijn geen sensoren gekoppeld</Alert><br/>
                </>
            ) : (
                <Cards>
                    {nodes.map((node) => (
                        <div key={node.id}>
                            {node.actuals.healtcheck ?
                                <GreenCard>
                                    <AreaTitle>{node.name}</AreaTitle>
                                    <Sensors>{node.description} <Green /> sensor is up</Sensors>
                                    {node.actuals !== null && node.model === 'RU13-NBIOT-EU' && <p><b>Vochtpercentage:</b> {node.actuals.vwc}%</p>}
                                    {node.actuals !== null && node.model === 'RU40-NBIOT-EU' && <p><b>Waterhoogte:</b> {node.actuals.level}mm</p>}
                                    {node.actuals !== null && node.model === 'RU40-NBIOT-EU' && <p><b>Berging:</b> {node.actuals.volume}L</p>}
                                </GreenCard>
                            :
                                <OrangeCard>
                                    <AreaTitle>{node.name}</AreaTitle>
                                    <Sensors>{node.description} <Orange /> sensor is down</Sensors>
                                    {node.actuals !== null && node.model === 'RU13-NBIOT-EU' && <p><b>Vochtpercentage:</b> {node.actuals.vwc}%</p>}
                                    {node.actuals !== null && node.model === 'RU40-NBIOT-EU' && <p><b>Waterhoogte:</b> {node.actuals.level}mm</p>}
                                    {node.actuals !== null && node.model === 'RU40-NBIOT-EU' && <p><b>Berging:</b> {node.actuals.volume}L</p>}
                                </OrangeCard>
                            }
                        </div>
                    ))}
                </Cards>  
            )}

        </Wrapper>
    )
};

const Wrapper = styled.main`
  padding: 20px 40px 80px 30px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    justify-content: space-between;
`;

const Node = styled.div`
    margin: 24px;
`;

const Title = styled.h1`
    margin-right: 50px;

    @media screen and (max-device-width: 400px){
        font-size: 16px;
    }

    @media screen and (min-device-width: 401px) and (max-device-width: 640px){
        font-size: 22px;
    }
`;

const Cards = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
 `;

 const GreenCard = styled.div`
    background:#fff;
    border-radius: 8px;
    margin: 1rem .25em;
    padding: 20px;
    width: 330px;
    height: 180px;
 `;

 const OrangeCard = styled.div`
    background:#fff;
    border-radius: 8px;
    border: 1px solid #EB5A37;
    margin: 1rem .25em;
    padding: 20px;
    width: 400px;
    height: 180px;
`;

 const AreaTitle = styled.p`
    font-size:18px;
    font-weight:bold;
`;

const Alert = styled.div`
    background: #F8D7DA;
    padding: 24px;
    color: #77232B;
    font-weight: bold;
`;

const Sensors = styled.div`
    display:flex; 
    flex-direction: row; 
    // justify-content: center; 
    // align-items: center"
`;

const Green = styled.p`
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #00C537;
    margin: 7px;
`;

const Orange = styled.p`
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #EE1414;
    margin: 7px;
`;

const Image = styled.img`
    padding-top: 0.5rem;
    height: 16px;
    margin-right: 6px;
`

export default withAuthenticator(Nodes);