import { createGlobalStyle } from 'styled-components';

import SuisseIntlRegularWebS from './SuisseIntl-Regular-WebS.woff';
import SuisseIntlRegularWebS2 from './SuisseIntl-Regular-WebS.woff2';

export default createGlobalStyle`
    @font-face {
      font-family: 'SuisseIntl';
      src: local('Font Name'), local('FontName'),
      url(${SuisseIntlRegularWebS2}) format('woff2'),
      url(${SuisseIntlRegularWebS}) format('woff');
      font-weight: 300;
      font-style: normal;
     }
  `;