import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import NodeService from "../services/nodes";
import { withAuthenticator } from '@aws-amplify/ui-react';

function ControlUnitModal({props, onClose, user}) {
    const [id, setId] = useState("");
    const [rule, setRule] = useState("");
    const [target, setTarget] = useState("");
    const [threshold, setThreshold] = useState("");
    const [flow, setFlow] = useState("");
    const modalRef = useRef();

    const closeModal = (e) => {
        if(modalRef.current === e.target){
            onClose();
        }
    }

    async function onSave(event) {
        event.preventDefault();
        NodeService.putNode(user, id, "RCU", rule, threshold, target, flow).then(result => {
            if(result.status === "200 OK"){
                onClose();
                console.log("put node ok", result);
            } else {
                console.log('Fetch error: ', result.status);
            }
            });
    }

    useEffect(() => {
        setRule(props["rule"]);
        setId(props["id"]);
        setTarget(props["target"]);
        setThreshold(props["threshold"]);
        setFlow(props["flow"]);
    }, [props]);

  return (
    <Container ref={modalRef} onClick={closeModal}>
      <Modal>
        <h2>Control unit</h2>
        <form onSubmit={onSave}>
            {props[0] === "1" && <><p>Regel: water toevoegen op basis van hoogte sensor</p><br/></>}
            <StyledLabel>Gewenst niveau (mm)</StyledLabel>
            <StyledInput type="number" name="target" value={target} onChange={(e) => setTarget(e.target.value)} />
            <StyledLabel>Bandbreedte (mm)</StyledLabel>
            <StyledInput type="number" name="threshold" value={threshold} onChange={(e) => setThreshold(e.target.value)} />
            <StyledLabel>Debiet (l/h)</StyledLabel>
            <StyledInput type="number" name="flow" value={flow} onChange={(e) => setFlow(e.target.value)} /><br/>
            <Button2 type="button" onClick={onClose}>Sluiten</Button2>
            <StyledButton type="submit">Opslaan</StyledButton>
        </form>
      </Modal>
    </Container>
  )
}

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
    font-family: Helvetica, sans-serif;
`;

const Modal = styled.div`
    background-color: white;
    padding: 22px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

export const StyledLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
`;

export const StyledInput = styled.input`
    // width:20%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
`
export const StyledButton = styled.button`
    min-width: 50px;
    border-radius: 4px;
    padding: 14px;
    border: none;
    background: #EB5A37;
    margin-top: 20px;
    color: #fff;
`
const Button2 = styled.button`
    min-width: 50px;
    border-radius: 4px;
    padding: 14px;
    border: none;
    color: #19303F;
    background: #E0E4E5;
    margin-top: 20px;
    margin-right: 14px;
`;

export default withAuthenticator(ControlUnitModal)