const Project = {
    getProject: async function(user) {
        const url= 'https://api.goodddata.com/v1/rainuppro/projects/';
        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                "Access-Control-Allow-Origin": "https://main.dovvjvfgt3b99.amplifyapp.com",
                "Access-Control-Request-Headers": "Content-Type, RUID",
                "Content-Type": "application/json",
                "RUID": user.attributes.sub,
                },
            })
            const result = await response.json();
            return result;
        } catch(err) {
            console.log('error fetchUsers ', err)
        }
    },

    getProjectDetails: async function(user, id) {
        const url= 'https://api.goodddata.com/v1/rainuppro/projects/'.concat('?project_id=',id);
        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                "Access-Control-Allow-Origin": "https://main.dovvjvfgt3b99.amplifyapp.com",
                "Access-Control-Request-Headers": "Content-Type, RUID",
                "Content-Type": "application/json",
                "RUID": user.attributes.sub,
                },
            })
            const result = await response.json();
            return result;
        } catch(err) {
            console.log('error fetchUsers ', err)
        }
    },
};

export default (Project);