import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import UserService from '../services/users';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MobileContext } from "./MobileContext";
import ScrollToTop from "./ScrollToTop";
import Layout from "../pages/Layout";
import Projects from "../pages/Projects";
import Contact from "../pages/Contact";
import ProjectNew from '../pages/ProjectNew';
import ProjectDetails from '../pages/ProjectDetails';
import NodeDetails from '../pages/NodeDetails';
import Nodes from '../pages/Nodes';
import NoPage from "../pages/NoPage";
import Maps from "../pages/Maps";
import { Auth } from 'aws-amplify';
import ClipLoader from "react-spinners/ClipLoader";

const Verified = ( { user }) => {
    const [windowDimension, setWindowDimension] = useState(null);
    const dataFetchedRef = useRef(false);
    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#ffffff");
    
    async function updateUser() {
      UserService.verifyUser(user, "SIGNIN").then(result => {
      if(result.status === "200 OK"){
          setVerified(true);
          setLoading(false);
      } else {
          console.log('Fetch error: ', result.status);
      }
      });
    }

    useEffect(() => {
        setWindowDimension(window.innerWidth);
        console.log("VERIFIED");
        console.log("verified user: ", user);
        if (user.attributes.sub) {
          console.log("verified user: ", user);
          //we use useRef to keep track of a boolean. 
          //If it’s true, we’ve already been here, and we can return. 
          //If it is false, we can perform our fetch and then update the ref.
          if (dataFetchedRef.current) return;
          dataFetchedRef.current = true;
          updateUser();
        }
      }, []);

      async function signOut() {
        //set logged_in boolean in user table to false
        UserService.verifyUser(user, "SIGNOUT").then(result => {
        console.log("ok", result);
        });
    
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    const isMobile = windowDimension <= 640;

    return (
        <>
        {loading && 
                <ClipLoader
                color={color}
                loading={loading}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
        }
        {!verified && !loading ? (
          <Center>
            Je bent niet geautoriseerd voor Rainup PRO<br/><br/>
            <Button onClick={signOut}>Sign out</Button>
        </Center>
        ) : (
            <>
            <BrowserRouter>
              <MobileContext.Provider value={isMobile}>
                <ScrollToTop />
                <Routes>
                  <Route path="/" element={<Layout />}>
                    <Route index element={<Projects />} />
                    <Route path="projects" element={<Projects />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="maps" element={<Maps />} />
                    <Route path="project-new" element={<ProjectNew />} />
                    <Route path="project-details/:id" element={<ProjectDetails />} />
                    <Route path="nodes/:id" element={<Nodes />} />
                    <Route path="node-details/:id" element={<NodeDetails />} />
                    <Route path="*" element={<NoPage />} />
                  </Route>
                </Routes>
              </MobileContext.Provider>
            </BrowserRouter>
        </>
    )}
    </>
    )
};

export default withAuthenticator(Verified);

const Center = styled.div`
  padding: 70px 0;
  text-align: center;
`
const Button = styled.button`
  min-width: 50px;
  background: #1976D2;
  border-radius: 8px;
  padding: 0 20px;
  border: none;
  border-bottom: 1px solid #2A67D2;
  color: #fff;
`;