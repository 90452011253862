import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
// import ProjectService from '../services/projects';

import { withAuthenticator } from '@aws-amplify/ui-react';

const ProjectNew = ({ user }) => {
    // const [projects, setProjects] = useState([]);
    const dataFetchedRef = useRef(false);

    async function updateUser() {
        // ProjectService.getProject(user).then(result => {
        //     if(result.status === "200 OK"){
        //         console.log("ok", result);
        //         setProjects(result.data);
        //     } else {
        //         console.log('Fetch error: ', result.status);
        //     }
        // });
    }

    useEffect(() => {
        //we use useRef to keep track of a boolean. 
        //If it’s true, we’ve already been here, and we can return. 
        //If it is false, we can perform our fetch and then update the ref.
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        updateUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <Header>
                <Title><Link to="/projects">&#8592;</Link> Nieuw Project</Title>
                <Button>+ Opslaan</Button>
            </Header>
            <div>
                Pagina voor het maken van een nieuw project!
            </div>
        </Wrapper>
    )
};

const Wrapper = styled.main`
  padding: 20px 40px 20px 30px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    justify-content: space-between;
`;

const Title = styled.h1`
    margin-right: 50px;

    @media screen and (max-device-width: 400px){
        font-size: 16px;
    }

    @media screen and (min-device-width: 401px) and (max-device-width: 640px){
        font-size: 22px;
    }
`;

const Button = styled.button`
    min-width: 50px;
    background: #3077F9;
    border-radius: 8px;
    padding: 0 20px;
    border: none;
    border-bottom: 1px solid #2A67D2;
    color: #fff;

    @media screen and (max-device-width: 400px){
        padding: 4px 20px;
    }

    @media screen and (min-device-width: 401px) and (max-device-width: 640px){
        padding: 10px 20px;
    }
`;

const Cards = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
 `;

 const Card = styled.div`
    background:#fff;
    // border: 1px solid #eeeeee;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 1rem .25em;
    padding: 20px;
    flex: 0 1 calc(50% - 1em);
    
    @media screen and (max-width: 640px) {
        flex: 0 1 calc(100%);
    }
 `;

export default withAuthenticator(ProjectNew);