import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
// import NodesService from '../services/nodes';
import MeasurementsService from '../services/measurements';
import TimeSeries from '../components/TimeSeries';

import { withAuthenticator } from '@aws-amplify/ui-react';

const NodeDetail = ({ user }) => {
    const [model, setModel] = useState([]);
    const [data, setData] = useState([]);
    const [battery, setBattery] = useState([]);
    const [title, setTitle] = useState();
    const dataFetchedRef = useRef(false);
    const params = useParams();
    const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}

    async function getMeasurements() {
        MeasurementsService.getMeasurements(user, params.id).then(result => {
            if(result.status === "200 OK"){
                // console.log("ok", result);
                setModel(result.node.model);
                setTitle(result.node.name);
                setData(result.data);
                setBattery(result.node.battery);
            } else {
                // setNode();
                console.log('Fetch error: ', result.status);
            }
        });
    }

    useEffect(() => {
        //we use useRef to keep track of a boolean. 
        //If it’s true, we’ve already been here, and we can return. 
        //If it is false, we can perform our fetch and then update the ref.
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getMeasurements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="Wrapper">
            <Link onClick={goBack}><Image src={'/arrow_left.svg'} alt="arrow left" /> terug naar project</Link>
            <Header>
                <Title>{title}</Title>
                {battery > 4 && <Summary><Number>80-100%</Number><Text>batterij</Text></Summary>}
                {battery > 3 && battery < 4 && <Summary><Number>60-80%</Number><Text>batterij</Text></Summary>}
                {battery > 2 && battery < 3 && <Summary><Number>40-60%</Number><Text>batterij</Text></Summary>}
                {battery > 1 && battery < 2 && <Summary><Number>20-40%</Number><Text>batterij</Text></Summary>}
            </Header>
            <div>
                {model==='RU40-NBIOT-EU' && <TimeSeries data={data}/>}
                {model==='RU13-NBIOT-EU' && <div>Nog geen grafiek beschikbaar</div>}
            </div>
        </div>
    )
};

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: space-between;
`;

const Image = styled.img`
    padding-top: 0.5rem;
    height: 12px;
  
`;

const Summary = styled.div`
    box-sizing: border-box;
    width: 150px;
`;

const Number = styled.div`
    font-size: 30px;
    font-weight: bold;
    border-bottom: 1px solid #000000;

    @media screen and (max-device-width: 400px){
        font-size: 24px;
    }
`;

const Text = styled.p`
    margin-top: 8px;
    font-size: 18px;
`;

const Title = styled.h1`
    margin-right: 50px;

    @media screen and (max-device-width: 400px){
        font-size: 14px;
    }

    @media screen and (min-device-width: 401px) and (max-device-width: 640px){
        font-size: 16px;
    }
`;

export default withAuthenticator(NodeDetail);