import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import Node from './Node';
import { Link } from "react-router-dom";
import ProgressBar from './ProgressBar';

const Area = (props) => {
    const [areas, setAreas] = useState([]);

    useEffect(() => {
        setAreas(props);
    }, [props]);

    return (
        <div>
        {!areas ? (
            <Alert>Er zijn geen gebieden geregistreerd</Alert>
        ) : (
            <>
            <Cards>
                {props.data.map((area) => (
                    // <div key={area.id}>
                        <Card key={area.id}>
                            <AreaTitle>{area.name}</AreaTitle>
                            <p>{area.description}</p>
                            <p><i>oppervlakte:</i> {area.square_metres} m2 ({area.length} m x {area.width} m)</p>
                            {area.nodes.length !== 0 ? (
                                <>
                                <Node props={area.nodes} isMobile={props.isMobile} edit={props.edit} ru13={area.RU13} ru40={area.RU40} area={area.name}/>
                                {/* <p>{area.nodes.length} {area.nodes.length === 1 ? "sensor" : "sensoren"}</p><br/>
                                <Icons>
                                    {area.RU13 && <Icon><Image src={'/drop.svg'} alt="drop icon" /> {area.average_ru13}%</Icon>}
                                    {area.RU40 && <Icon><Image src={'/level.svg'} alt="level icon" /> {area.average_ru40}mm</Icon>}
                                </Icons> */}
                                </>
                            ) : (
                                <>
                                    <Sensors><Orange /> Er zijn geen sensoren gekoppeld</Sensors>
                                    <br/><br/>
                                </>
                            )}

                        </Card>
                    // </div>
                ))}
            </Cards>
            </>
        )}
        </div>
    )
};

const Cards = styled.div`
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
 `;

 const Card = styled.div`
    background:#fff;
    // border: 1px solid #eeeeee;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 2rem .25em;
    padding: 20px;
    flex: 1 1 160px;
    max-width: 400px;
    // flex-grow: 1;
 `;

 const Icons = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
 `;

 const Icon = styled.div`
    background:#fff;
    box-sizing: border-box;
    padding-right: 22px;
`;

 const AreaTitle = styled.p`
    font-size:18px;
    font-weight:bold;
`;

const Image = styled.img`
    padding-top: 0.5rem;
    height: 16px;
    margin-right: 6px;
`
const Sensors = styled.div`
    display:flex; 
    flex-direction: row; 
    // justify-content: center; 
    // align-items: center"
`;

const Alert = styled.div`
    background: #F8D7DA;
    padding: 18px;
    color: #77232B;
    margin-bottom: 22px;
    font-size: 12px;
`;

const Orange = styled.p`
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #EE1414;
    margin: 7px 7px 0px 0px;
`;

export default Area;