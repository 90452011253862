import styled from "styled-components";

const NoPage = () => {
    return (
        <Wrapper>
            <h1>404</h1>
        </Wrapper>
    )
};

const Wrapper = styled.main`
  padding: 2em;
`;

export default NoPage;