import React, { useEffect, useState } from "react";
import styled from "styled-components";
import '../App.css';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

function TimeSeries({ data }) {
  const [nodata, setNoData] = useState(false);

  useEffect(() => {
    if(data.length===0){
      setNoData(true);
    }
  }, [data]);

  // const options = {
  //   chart: {
  //     alignTicks: false,
  //     zooming: {
  //       type: 'x'
  //   }
  // },
  // rangeSelector: {
  //     selected: 1
  // },
  // title: {
  //     text: 'Waterhoogte'
  // },

  // series: [{
  //     type: 'column',
  //     name: 'Waterhoogte',
  //     data: data,
  //     color: '#E0E4E5'
  //     // dataGrouping: {
  //     //     units: [[
  //     //         'week', // unit name
  //     //         [1] // allowed multiples
  //     //     ], [
  //     //         'month',
  //     //         [1, 2, 3, 4, 6]
  //     //     ]]
  //     // }
  // }]
  // };

  const options = {
    rangeSelector: {
      allButtonsEnabled: false,
      selected: 0
    },
    chart: {
        zooming: {
            type: 'x'
        }
    },
    title: {
        text: 'Waterhoogte',
        align: 'left'
    },
    // subtitle: {
    //     text: document.ontouchstart === undefined ?
    //         'Click and drag in the plot area to zoom in' :
    //         'Pinch the chart to zoom in',
    //     align: 'left'
    // },
    xAxis: {
        type: 'datetime'
    },
    yAxis: {
        title: {
            text: 'waterhoogte (mm)',
        }
    },
    legend: {
        enabled: true
    },
    plotOptions: {
        area: {
            marker: {
                radius: 2
            },
            lineWidth: 1,
            color: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                },
                stops: [
                    [0, 'rgb(199, 113, 243)'],
                    [0.7, 'rgb(76, 175, 254)']
                ]
            },
            states: {
                hover: {
                    lineWidth: 1
                }
            },
            threshold: null
        }
    },
  
    series: [{
        type: 'area',
        name: 'system level',
        data: data
    }]
  };

  return (
    <div>
      {nodata ? (
          <Alert>Er is geen data aanwezig</Alert>
      ):(
        <><Wrap><HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={options} /></Wrap></>
      )}
    </div>

    
  )
}

export default TimeSeries;

const Wrap = styled.div`
    background: #FFF;
    border-radius: 8px;
    padding: 20px;
`;

const Alert = styled.div`
    background: #F8D7DA;
    // background: #FFF3CD;
    padding: 18px;
    color: #77232B;
    margin-bottom: 22px;
    font-size: 12px;
`;