import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import ProjectService from '../services/projects';
import { MobileContext } from '../components/MobileContext';

import { withAuthenticator } from '@aws-amplify/ui-react';

const ProjectNew = ({ user }) => {
    const [projects, setProjects] = useState([]);
    const dataFetchedRef = useRef(false);
    const isMobile = useContext(MobileContext);

    async function getProject() {
        ProjectService.getProject(user).then(result => {
            if(result.status === "200 OK"){
                console.log("projects", result);
                setProjects(result.data);
            } else {
                setProjects();
                console.log('Fetch error: ', result.status);
            }
        });
    }

    useEffect(() => {
        //we use useRef to keep track of a boolean. 
        //If it’s true, we’ve already been here, and we can return. 
        //If it is false, we can perform our fetch and then update the ref.
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getProject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <Header>
            <Title>Projects</Title>
            <Button3>
                <Link to="/projects">
                    {!isMobile && <><i className="fa-solid fa-list"></i> Lijst</>}
                    {isMobile && <i className="fa-solid fa-list fa-xl"></i>}
                </Link>
            </Button3>
            </Header>
            
            <div>
                {!projects ? (
                    <div>Er zijn geen projecten geregistreerd</div>
                ) : (
                    <>
                        <div>Toon google maps kaart met projecten</div>
                    </>
                )}
            </div>
        </Wrapper>
    )
};

const Wrapper = styled.main`
    padding: 3em 2em;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    justify-content: space-between;
`;

const Title = styled.h1`
    margin-right: 50px;
`;

const Button = styled.button`
    min-width: 50px;
    background: #1976D2;
    border-radius: 6px;
    padding: 0 20px;
    border: none;
    border-bottom: 1px solid #2A67D2;
    color: #fff;
`;

const Button2 = styled.button`
    min-width: 50px;
    border-radius: 6px;
    padding: 6px 12px;
    border: none;
    border: 1px solid #000;
    background: #fff;
    margin-top: 20px;
`;

const Button3 = styled.button`
    min-width: 50px;
    border-radius: 6px;
    padding: 6px 12px;
    border: none;
    border: 1px solid #000;
    background: #fff;

    ::hover {
        background-color: #000;
    }
`;

const Cards = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
 `;

 const Card = styled.div`
    background:#fff;
    // border: 1px solid #eeeeee;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 1rem .25em;
    padding: 20px;
    flex: 0 1 calc(50% - 1em);
    
    @media screen and (max-width: 640px) {
        flex: 0 1 calc(100%);
    }
 `;

export default withAuthenticator(ProjectNew);