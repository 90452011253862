const User = {
    verifyUser: async function(user, method) {
        const url= 'https://api.goodddata.com/v1/rainuppro/users/'.concat('?method=',method);
        try {
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                "Access-Control-Allow-Origin": "https://main.dovvjvfgt3b99.amplifyapp.com",
                "Access-Control-Request-Headers": "Content-Type, RUID, RUEMAIL, RUNAME",
                "Content-Type": "application/json",
                "RUID": user.attributes.sub,
                "RUEMAIL": user.attributes.email,
                "RUNAME": user.attributes.name,
                },
            })
            const result = await response.json();
            return result;
        } catch(err) {
            console.log('error fetchUsers ', err)
        }
    },

    secondValidationMethod: function(value) {
        //inspect the value
    }
};

export default (User);