import React from 'react';
import { Account } from "./components/Account";
import './App.css';
import "@aws-amplify/ui-react/styles.css";
import GlobalFonts from './fonts/fonts';
import Signup from './components/Signup';
import Login from './components/Login';

function App({ user }) {

  return (
    <>
    <GlobalFonts />  
    <Account>
      {/* <Status /> */}
      {/* <Signup /> */}
      <Login />
    </Account>
    </>
  );
}

export default App;
