const Node = {
    getNodes: async function(user, id) {
        const url= 'https://api.goodddata.com/v1/rainuppro/nodes/'.concat('?area_id=',id);
        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                "Access-Control-Allow-Origin": "https://main.dovvjvfgt3b99.amplifyapp.com",
                "Access-Control-Request-Headers": "Content-Type, RUID",
                "Content-Type": "application/json",
                "RUID": user.attributes.sub,
                },
            })
            const result = await response.json();
            return result;
        } catch(err) {
            console.log('error fetchNodes ', err)
        }
    },
    getNode: async function(user, id) {
        const url= 'https://api.goodddata.com/v1/rainuppro/nodes/'.concat('?node_id=',id);
        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                "Access-Control-Allow-Origin": "https://main.dovvjvfgt3b99.amplifyapp.com",
                "Access-Control-Request-Headers": "Content-Type, RUID",
                "Content-Type": "application/json",
                "RUID": user.attributes.sub,
                },
            })
            const result = await response.json();
            return result;
        } catch(err) {
            console.log('error fetchNodes ', err)
        }
    },
    putNode: async function(user, id, type, rule, threshold, target, flow) {
        const url= 'https://api.goodddata.com/v1/rainuppro/nodes/'.concat('?id=',id,'&type=',type,'&rule=',rule,'&threshold=',threshold,'&target=',target,'&flow=',flow);
        try {
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                "Access-Control-Allow-Origin": "https://main.dovvjvfgt3b99.amplifyapp.com",
                "Access-Control-Request-Headers": "Content-Type, RUID",
                "Content-Type": "application/json",
                "RUID": user.attributes.sub,
                },
            })
            const result = await response.json();
            return result;
        } catch(err) {
            console.log('error putNode ', err)
        }
    }
};

export default (Node);